<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col >
        <v-text-field dense label="Descrição" v-model="currentItem.nome_substancia" outlined :rules="requiredField" />
      </v-col>
      <v-col >
        <v-autocomplete dense label="Apresentação para Prescrição" :item-text="apresentacaoText" v-model="currentItem.apresentacao_prescricao" :items="apresentacao_prescricao"  item-value="id" outlined :rules="requiredField" :loading="isLoadingGeral"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="2">
        <v-autocomplete dense label="Classificação" v-model="currentItem.classificacao" :items="classificacao" item-text="nome" item-value="id" outlined :loading="isLoadingGeral" :rules="requiredField"/>
      </v-col>
      <v-col cols="2">
        <v-autocomplete dense :items="statusOptions" label="Item Padrão" v-model="currentItem.padrao" item-text="label" item-value="value" outlined :rules="requiredField"/>
      </v-col>
      <v-col cols="2">
        <v-autocomplete dense :items="statusOptions" label="Alto custo" v-model="currentItem.alto_custo" item-text="label" item-value="value" outlined :rules="requiredField"  />
      </v-col>
      <v-col cols="2">
        <vuetify-money dense label="Custo estimado " v-model="currentItem.custo" outlined :options="options"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="3">
        <v-autocomplete dense :items="armazenamentoOptions" label="Temperatura Armazenamento" v-model="currentItem.armazenamento" item-text="label" item-value="value" outlined :rules="requiredField"  />
      </v-col>
      <v-col >
       <v-autocomplete dense label="Endereço" v-model="currentItem.enderecos" :items="endereco" :item-text="enderecoText" item-value="id" outlined :loading="isLoadingGeral"  :rules="requiredField"  multiple chips/>
     </v-col>
    </v-row>
    <v-row dense>
       <v-col >
        <v-autocomplete dense label="Tabela de Preço Material" v-model="currentItem.tabela_simpro" :items="simpro" :item-text="simproText" item-value="id" outlined :loading="isLoadingSimpro" />
      </v-col>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <v-btn  dense style="color: red; font-weight: bold;" @click="limpar" class="mr-5">Limpar </v-btn>
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "NovoMaterial",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      requiredField: [e => (e !== null && e !== undefined && e !== '' ? true : 'Obrigatório')],
      isLoadingSimpro: false,
      isLoadingGeral: false,
      tipo: [],
      classificacao: [],
      apresentacao_prescricao: [],
      endereco: [],
      simpro: [],
      statusOptions: [
        { label: "Sim", value: true },
        { label: "Não", value: false }
      ],
       options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2,
      },
      armazenamentoOptions: [
        { label: "2ºC a 8ºC - Refrigerado", value: true },
        { label: "15ºC a 30ºC - Temperatura ambiente", value: false },
      ],
      
    })
  },
  components: { ConfirmButton },
  methods: {
    localOnSubmit() {
      const validation = this.$refs.formRef.validate()
      if (!validation)  return;

      const fields = {
        ...this.currentItem,
        custo: this.currentItem.custo ? this.currentItem.custo : 0,
        tipo: 2, // tipo medicamento
      }
      // console.log('Enviado => ', fields)
      this.onSubmit(fields)
    },
    async getTipoMatMedBase() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/tipo/");
        this.tipo = data.filter(item => item.ativo);
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getClassificacao() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/classificacao/");
        this.classificacao = data
          .filter(item => item.ativo && [3,4, 5, 6, 7, 8, 9].includes(item.id))
          .sort((a,b) => a.nome.localeCompare(b.nome));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getApresentacao() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/apresentacao/");
        this.apresentacao_prescricao = data.filter(item => item.ativo).sort((a,b) => a.nome.localeCompare(b.nome));
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
     async getEndereco() {
      this.isLoadingGeral = true
      try {
        const { data } = await api.get("/matmed/enderecos/");
        this.endereco = data
          .filter(item => item.ativo)
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getSimpro() {
      this.isLoadingSimpro = true
      try {
        const { data } = await api.get("/matmed/simpro/");
        this.simpro = data
          .filter(item => item.ativo) // Filtra os ativos que não são dieta
          .sort((a, b) => a.descricao.localeCompare(b.descricao)); // Ordena pela descrição
        // console.log(this.simpro)
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.isLoadingSimpro = false
      }
    },
    limpar(){
      this.currentItem.nome_substancia = null
      this.currentItem.apresentacao_prescricao = null
      this.currentItem.classificacao = null
      this.currentItem.padrao = null
      this.currentItem.custo = 0
      this.currentItem.armazenamento = null
      this.currentItem.endereco = null
      this.currentItem.alto_custo = null
    }
  },
  computed:{
    apresentacaoText() {
      return (item) => {
          return `${item.nome} - ${item.abreviatura}`;
      }
    },
     enderecoText() {
      return (item) => {
          return `Rua: ${item.rua} - Prédio: ${item.predio} - APT: ${item.apt}`;
      }
    },
     simproText() {
      return (item) => {
          return `${item.descricao} - ${item.complemento_descricao} - Fabricante ${item.fabrica} - Cód. Simpro ${item.cod_simpro} - Preço FAB UND R$ ${item.preco_fab_unidade}`;
      }
    },
  },
  mounted() {
    this.getTipoMatMedBase()
    this.getClassificacao()
    this.getApresentacao()
    this.getEndereco()
    this.getSimpro()
  }
}
</script>
