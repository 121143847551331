<template>
  <v-container fluid>
    <!-- <prev>{{item}}</prev> -->
    <v-row dense>
      <v-col>
        <h3><b>Mais detalhes...</b></h3>
        <p><b>Custo R$: </b>{{formatarReal(item.custo)}} - <b>Precificação Sugerida R$: </b>{{ formatarReal(getPrecoPrecificacao(item)) }}</p>
       
        <p v-if="item.tabela_simpro">
          <b>Tabela de Preço Simpro :</b> 
          {{ item.tabela_simpro?.descricao || "N/A" }} - 
          Fabricante {{ item.tabela_simpro?.fabrica || "N/A" }} - 
          Cód. TUSS {{ item.tabela_simpro?.cod_TUSS || "Não cadastrado" }} - 
          Cód. Simpro {{ item.tabela_simpro?.cod_simpro || "N/A" }} - 
          Preço FAB UND R$ {{ formatarReal(item.tabela_simpro?.preco_fab_unidade) || "0,00" }}
        </p>
        <p v-else><b>Não há dados disponíveis para Tabela de Preço Material.</b></p>

        <div v-if="loadingTabela" class="text-center my-3">
          <v-progress-circular indeterminate color="primary" size="50"></v-progress-circular>
        </div>

        <div v-else-if="tabelaPropria.length">
          <p><b>Tabela de Preço Própria:</b></p>
          <ul>
            <li v-for="(item, index) in tabelaPropria" :key="index">
              <b>Operadora:</b> {{ item?.operadora?.nome || "N/A" }} - 
              <b>Código:</b> {{ item?.codigo || "N/A" }} | 
              <b>Tabela:</b> {{ item?.tabela?.tabela || "N/A" }} - {{ item?.tabela?.nome || "N/A" }} | 
              <b>Cód. Despesa:</b> {{ item?.despesa?.despesa || "N/A" }} - {{ item?.despesa?.nome || "N/A" }} | 
              <b>Tipo de Guia:</b> {{ item?.tipoguia?.tipoguia || "N/A" }} | {{ item?.tipoguia?.nome || "N/A" }} | 
              <b>Preço:</b> R$ {{ formatarReal(item?.preco) || "0,00" }}
            </li>
          </ul>
        </div>

     
        <p v-else><b>Não há dados disponíveis para Tabela de Preço Própria.</b></p>

      </v-col>
    </v-row> 
  </v-container>
</template>

<script>
import api from '../../../../http';

export default {
  name: "SimproExpand",
  props: {
    item: Object,
  },
  data: () => ({
    isPageLoading: true,  // 🔥 Ativa o loading ao carregar a página
    loadingTabela: false,  // Loading específico para tabela
    tabelaPropria: [],
  }),
  methods: {
    async getSimpro() {
      this.loadingTabela = true;
      this.tabelaPropria = []; // Limpa antes de carregar os dados
      try {
        // console.log(this.item.id);
        const { data } = await api.get("/matmed/tabela_propria/");
        this.tabelaPropria = data.filter(item => item.ativo && item.produto.id === this.item.id);
        // console.log("Tabela Própria Filtrada:", this.tabelaPropria);
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      } finally {
        this.loadingTabela = false;
        this.isPageLoading = false; // 🔥 Remove o loading global quando os dados carregam
      }
    },
    formatarReal(value) {
      if (!value) return "R$ 0,00";
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }).format(value);
    },
    getPrecoPrecificacao(item) {
      if (!item?.custo) return null;  // Retorna null ao invés de 0

      const custo = parseFloat(item.custo) || 0;
      const percentualCusto = parseFloat(item.custo_empresa) || 0;

      const resultado = custo * (1 + percentualCusto / 100);
      
      return resultado > 0 ? resultado : null; // Retorna null se for 0 ou inválido
    }

  },
  
  mounted() {
    this.getSimpro(); // Chama os dados ao carregar a página
  },
};
</script>
